<template>
  <div class="login_wrap">
    <div class="login">
      <div class="left">
        <img src="../assets/img/logo.png" class="logo" />
        <h1>南亚东南亚建设工程材料及设备价格波动<br />监测</h1>
        <p class="copyright">
          Copyright 2018-2019 版权所有 昆明行列科技有限公司 Ver 2.1.2
        </p>
      </div>
      <div class="right">
        <h1>用户登录</h1>
        <div
          class="account"
          :class="{
            account_focus: accountStatus == 1,
            account_error: accountError,
          }"
        >
          <i>账号</i>
          <input
            type="text"
            v-model="account"
            :class="{ error: accountError }"
            placeholder="请输入账号"
            @blur="handleBlur('account')"
            @focus="handleFocus('account')"
          />
          <p class="error_tips">{{ accountError }}</p>
        </div>
        <div
          class="password"
          :class="{
            password_focus: passwordStatus == 1,
            password_error: passwordError,
          }"
        >
          <i>账号</i>
          <input
            type="password"
            v-model="password"
            :class="{ error: passwordError }"
            placeholder="请输入密码"
            @blur="handleBlur('password')"
            @focus="handleFocus('password')"
          />
          <p class="error_tips">{{ passwordError }}</p>
        </div>
        <el-button
          :loading="isSubmit"
          :disabled="isDisable"
          @click="handleSubmit"
          @keyup.native.enter="handleSubmit"
          >登录</el-button
        >
        <h2>
          <a @click="$router.push('/')"><i>＜</i>返回首页</a>
        </h2>
      </div>
    </div>
  </div>
</template>
<script>
// import * as Types from "../store/mutations-types";
export default {
  name: "login",
  data() {
    return {
      account: "",
      password: "",
      accountError: "",
      passwordError: "",
      accountStatus: 0, // 账号是否获取焦点
      passwordStatus: 0, // 密码是否获取焦点
      isSubmit: false,
    };
  },
  beforeCreate() {
    if (Object.keys(this.$store.state.userInfo).length)
      this.$router.push("/ReportData");
  },
  computed: {
    isDisable() {
      return !(this.account.length && this.password.length);
    },
  },
  methods: {
    async handleSubmit() {
      // this.$alert('系统即将开放查询功能，敬请期待！','温馨提示')
      // this.$message.warning('南亚东南亚建设工程材料及设备价格波动监测正在开发中，尽请期待！')
      this.isSubmit = true;
      let [err, data] = await this.$utils.awaitFn(
        this.$reqApi.login({ account: this.account, password: this.password })
      ); //如果错误获取错误处理
      if (err) {
        console.log(err);
        return (this.isSubmit = false);
      }
      let { userinfo, password, account } = data;
      this.isSubmit = false;
      if (userinfo) {
        // 登录成功
        this.$message.success("恭喜你，登录成功!");
        this.$store.commit("storeSaveUserInfo", userinfo);
        this.$router.push("/ReportData");
      } else if (password || account) {
        // 账号密码错误
        this.accountError = account;
        this.passwordError = password;
      }
    },
    handleBlur(type) {
      //失去焦点
      this[type] = this[type].trim();
      !this[type].length
        ? (this[`${type}Error`] =
            type == "account" ? "账号不能为空" : "密码不能为空")
        : null;
      this.accountStatus = this.passwordStatus = 0;
    },
    handleFocus(type) {
      //获取焦点
      this[`${type}Error`] = "";
      type == "account" ? (this.accountStatus = 1) : (this.passwordStatus = 1);
    },
    enterKeycodeFn(e) {
      //enter键登录
      const keycode = document.all ? event.keyCode : e.which;
      if (keycode === 13 && !this.isDisable) {
        this.handleSubmit();
        return false;
      }
    },
  },
  mounted() {
    document.addEventListener("keydown", this.enterKeycodeFn);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.enterKeycodeFn);
  },
};
</script>

<style lang="less" scoped>
.login_wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../assets/img/loginbg-1.jpg") no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  .login {
    font-size: 12px;
    line-height: 20px;
    display: flex;
    .left {
      width: 360px;
      height: 480px;
      border-radius: 10px;
      background: url("../assets/img/loginbg-2.png") no-repeat center center;
      background-size: 100%;
      text-align: center;
      position: relative;
      margin-right: 10px;
      color: #fff;
      .logo {
        margin-top: 128px;
      }
      h1 {
        font-size: 16px;
        margin-top: 20px;
        line-height: 28px;
      }
      .copyright {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 20px;
        color: #a6a6a6;
        font-size: 12px;
      }
    }
    .right {
      width: 480px;
      height: 480px;
      background: #ffffffd9;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
        font-size: 24px;
        text-align: center;
        margin: 80px 0 50px;
      }
      .account,
      .password {
        height: 60px;
        position: relative;
        i {
          position: absolute;
          top: 6px;
          left: 6px;
          width: 16px;
          height: 20px;
          text-indent: -999em;
        }
        input {
          width: 300px;
          height: 34px;
          border-bottom: 1px solid #c6c9d6;
          padding-left: 30px;
          box-sizing: border-box;
          background-color: transparent;
          &:focus {
            border-bottom: 2px solid #454eff;
          }
          &.error {
            border-bottom: 2px solid #ff1a25;
          }
        }
        .error_tips {
          width: 300px;
          text-align: left;
          color: #ff1a25;
        }
      }
      .account i {
        background: url("../assets/img/loginIcon-1.png") no-repeat 0 0;
      }
      .password i {
        background: url("../assets/img/loginIcon-2.png") no-repeat 0 0;
      }
      .account_focus i,
      .password_focus i {
        background-position: 0px -40px;
      }
      .account_error i,
      .password_error i {
        background-position: 0px -80px;
      }
      button {
        margin: 16px 0 26px;
        height: 42px;
        width: 300px;
        background: #454eff;
        box-shadow: 0 8px 12px 0 rgba(59, 110, 255, 0.3);
        border-radius: 4px;
        color: #fff;
        font-weight: bold;
        letter-spacing: 6px;
        font-size: 14px;
        cursor: pointer;
        &[disabled] {
          background: #8e9099;
          box-shadow: 0 8px 12px 0 rgba(142, 144, 153, 0.3);
          cursor: not-allowed;
        }
      }
      h2 {
        width: 300px;
        a {
          font-size: 14px;
          color: #454eff;
          cursor: pointer;
          font-weight: normal;
          i {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
